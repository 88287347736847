import jwtDecode from "jwt-decode";
import React, { createContext, useEffect, useState } from "react";

export const stateContext = createContext();
const MainContext = ({ children }) => {
  // urls
  const URL_API = "https://api.riyada.iq/api/";
  // const URL_API = "https://phpstack-912641-3237802.cloudwaysapps.com/api/";

  //token
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("token") || null)
  );
  // pagination
  const [currentPage, setCurrentPage] = useState(1);

  // stated
  const [isLoading, setIsLoading] = useState(false);

  // get info user
  const [infoUser, setInfoUser] = useState(null);
  useEffect(() => {
    token && setInfoUser(jwtDecode(token));
  }, [token]);

  //
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("profile") || null)
  );

  return (
    <stateContext.Provider
      value={{
        URL_API,
        token,
        setToken,
        isLoading,
        setIsLoading,
        infoUser,
        currentPage,
        setCurrentPage,
        profile,
        setProfile,
      }}
    >
      {children}
    </stateContext.Provider>
  );
};

export default MainContext;

// add centers to form
