import React, { lazy, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { stateContext } from "./context/MainContext";
import Spinner from "./components/Spinners/Spinner";
import { MantineProvider, createEmotionCache } from "@mantine/core";
import stylisRTLPlugin from "stylis-plugin-rtl";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));

function App() {
  const { token, isLoading } = useContext(stateContext);
  const rtlCache = createEmotionCache({
    key: "mantine-rtl",
    stylisPlugins: [stylisRTLPlugin],
  });

  return (
    <div>
      <Router>
        <AccessibleNavigationAnnouncer />
        <MantineProvider
          theme={{
            fontFamily: "SomarSansMedium",
            defaultGradient: { from: "#24AE60", to: "#56B4D3", deg: 90 },
          }}
          emotionCache={rtlCache}
        >
          <Switch>
            <Route path="/">{token ? <Layout /> : <Login />}</Route>
            <Route path="/login" component={Login} />
          </Switch>
        </MantineProvider>
      </Router>

      {isLoading && <Spinner />}
    </div>
  );
}

export default App;
