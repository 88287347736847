import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/css/tailwind.output.css";
import App from "./App";
import { SidebarProvider } from "./context/SidebarContext";
import ThemedSuspense from "./components/ThemedSuspense";
import { Windmill } from "@windmill/react-ui";
import MainContext from "./context/MainContext";

ReactDOM.render(
  <SidebarProvider>
    <MainContext>
      <Suspense fallback={<ThemedSuspense />}>
        <Windmill>
          <App />
        </Windmill>
      </Suspense>
    </MainContext>
  </SidebarProvider>,
  document.getElementById("root")
);
